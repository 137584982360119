import React from "react";
import "./DefaultRoute.css";

const DefaultRoute = () => {
  return (
    <div className="default-route">
      <h2>Please select the player</h2>
    </div>
  );
}

export default DefaultRoute;